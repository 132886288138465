.expandable-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expandable-title {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
}

.focal-block {
  height: 16px;
}

.focal-radio-wrapper {
  display: flex;
}

.custom-radio {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-radio+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.custom-radio+label::before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid #fff;
  border-radius: 50%;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
}

.custom-radio:checked+label::before {
  background-image: url('../../../img/radio.svg');
}

.layers-paragraph {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
}

.switch {
  position: relative;
  display: inline-block;
  border: 2px solid #fff;
  border-radius: 10px;
  width: 22px;
  height: 12px;
  margin-right: 9px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 1px;
  top: 1px;
  background-color: #fff;
  transition: .3s;
}

input:checked+.slider:before {
  transform: translateX(10px);
}

.slider.round:before {
  border-radius: 50%;
}

.checked_label {
  text-transform: uppercase;
  margin-right: 8px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
}

.expandable-txt {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin: 0;
}
