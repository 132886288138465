@mixin lib-icon {
	content: '';
	display: block;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	position: absolute;
	top: 20px;
	left: 50%;
	transform: translateX(-50%);
	width: 24px;
	height: 24px;
}

.menu-section-block {
	max-width: 80px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media (max-width: 1019px) {
		display: none;
	}
}

.lib-btn {
	width: 80px;
	height: 108px;
	background-color: transparent;
	color: #fff;
	border: 2px solid transparent;
	cursor: pointer;
	// padding: 40px 18px 16px 18px;
	padding-top: 40px;
	padding-bottom: 16px;
	line-height: 20px;
	position: relative;
	font-size: 16px;

	&:hover {
		// background-color: #131318;
		border: 2px solid #337AA6;
	}
}

.lib-btn_active {
	background-color: #131318;
}

.lib-btn_center {
	padding-left: 18px;
	padding-right: 18px;
}

.focal-planes_btn {

	&::before {
		@include lib-icon;

		background-image: url('../../../img/editorIcon.svg');
	}
}

.focal-planes_btn-active {

	&::before {
		@include lib-icon;

		background-image: url('../../../img/editorIconActive.svg');
	}
}

.user-library_btn {

	&::before {
		@include lib-icon;

		background-image: url('../../../img/my-lib_btn.svg');
	}
}

.user-library_btn-active {

	&::before {
		@include lib-icon;

		background-image: url('../../../img/my-lib_btn-active.svg');
	}
}

.public-library_btn {

	&::before {
		@include lib-icon;

		background-image: url('../../../img/libraryIcon.svg');
	}
}

.public-library_btn-active {

	&::before {
		@include lib-icon;

		background-image: url('../../../img/libraryIconActive.svg');
	}
}

.log-in_btn {

	&::before {
		@include lib-icon;

		background-image: url('../../../img/log-in-active.svg');
	}
}


.log-in_btn-active {

	&::before {
		@include lib-icon;

		background-image: url('../../../img/log-in.svg');
	}
}

.log-in_userBtn {
	padding: 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.user-photo {
	display: inline-block;
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

.menu-secction_user {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.user-name_title {
	margin: 8px 0 0 0;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
}

.user-link {
	text-decoration: none;
	color: #fff;
}

.user-menu {
	position: absolute;
	width: 140px;
	min-height: 150px;
	left: 100%;
	bottom: 0;
	cursor: auto;
	padding: 8px;
	background: #3F414B;
	border: 1px solid #454751;
	box-sizing: border-box;
	box-shadow: 0px 1px 3px rgba(26, 26, 33, 0.5);
	border-radius: 2px;
	font-size: 12px;
	line-height: 16px;
	z-index: 2;
	text-align: center;
}
