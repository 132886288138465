.reticles_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.reticles_section-input {
    background-color: transparent;
    border: none;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    padding: 3px 5px;
    width: 100px;
    margin: 17px 5px;
}

.reticles_name {
    display: flex;
    align-items: center;
    margin-right: 15px;
    font-size: 16px;
}

.reticles-btn {
    position: relative;
    margin: 0;
    background-color: transparent;
    width: 190px;
    height: 64px;
    color: #fff;
    border: 2px solid transparent;
    font-size: 16px;
    text-align: start;

    &:hover {
        cursor: pointer;
        border: 2px solid #337AA6;
        background-color: #131318;
    }
}

.reticles-btn-icon {
    &::after {
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        bottom: 33%;
        right: 0;
        width: 18px;
        height: 15px;
        margin-right: 10px;
        background-image: url('../../../img/editing-icon.svg');
    }
}

.reticles-btn-active {
    background-color: #131318;
}
