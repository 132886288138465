@mixin lib-icon {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 24px;
    height: 24px;
}

.modal {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    // text-align: center;
    //   top: 44px;
    //   left: 0;
    //   width: 100%;
    //   height: calc(100% - 44px);
    // display: flex;
    // justify-content: center;
    // align-items: center;
    background-color: #383844;
    border-radius: 24px;
    z-index: 1200;
}

.share {
    width: 469px;

    @media (max-width: 767px) {
        width: 338px;
    }
}

.delete {
    width: 423px;

    @media (max-width: 767px) {
        width: 338px;
    }
}

.burger {
    width: 299px;
}

.filter {
    width: 312px;
}

.modal__btn {
    display: inline-flex;
    padding: 0;

    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #fff;
    position: absolute;
    top: 35px;
    right: 40px;
}

.overlay {
    position: fixed;
    z-index: 1200;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(23, 23, 25, 0.3);
}

.modal-text {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 444px;
}

.modal-list {
    padding: 0px;
    display: flex;
    // flex-wrap: wrap;
}

// .modal__svg {
//     margin-right: 9px;
// }

.modal-delete-actionButton {
    @media (max-width: 767px) {
        width: 223px;
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
    }
}

.modal-delete-button {
    @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 10px;
    }
}
