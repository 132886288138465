.previewNotificationsSection {
    position: absolute;
    display: flex;
    height: 32px;
    background-color: #FF5050;
}

.previewNotificationsActiveLink {
    width: calc(100% - 415px);
}

.previewNotificationsNotActiveLink {
    width: calc(100% - 80px);
}
