@mixin share-icon {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    top: 4px;
    left: 36px;
    transform: translateX(-50%);
}

.share-section {
    width: 80px;
    position: relative;
}

.share-btn {
    width: 80px;
    height: 40px;
    left: 0px;
    top: 0px;
    border: 2px solid #337aa6;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: transparent;
    color: #fff;
    cursor: pointer;

    &:hover {
        background-color: #225A7C;
        border: 1px solid #225A7C;
    }

    &:active {
        background-color: #16405A;
        border: 1px solid #16405A;
    }
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #080808;
    position: absolute;
    top: -5px;
    right: 50%;
    transform: translateX(50%);
}

.share-block {
    position: absolute;
    width: 180px;
    // height: 160px;
    border-radius: 8px;
    padding: 8px 0;
    margin: 0;
    background-color: #080808;
    top: 50px;
    right: 50%;
    transform: translateX(50%);
    box-sizing: border-box;
    z-index: 100;
    display: none;
}

.share-item {
    width: 180px;
    height: 36px;
    border: 2px solid transparent;
    box-sizing: border-box;
    padding: 6px 6px 6px 56px;
    position: relative;
    display: inline-block;
    text-align: left;
    cursor: pointer;

    &:hover {
        border-color: #337aa6;
    }
}

.fb-ico {
    &::before {
        @include share-icon;
        background-image: url('../../../img/facebook_share-ico.svg');
    }
}

.tw-ico {
    &::before {
        @include share-icon;
        background-image: url('../../../img/twitter_share-ico.svg');
    }
}

.mp-ico {
    &::before {
        @include share-icon;
        background-image: url('../../../img/make-public_share-ico.svg');
    }
}

.gl-ico {
    &::before {
        @include share-icon;
        background-image: url('../../../img/get-link_share-ico.svg');
    }
}

.share-block_active {
    display: block;
}
