.shapesButtonsSection {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 30%;
    left: 414.5px;
}

.shapesButton {
    width: 48px;
    height: 48px;
    background-color: #1f1f26;
    border: 1px solid #171719;
    cursor: pointer;
    position: relative;

    &:hover {
        border: 2px solid #337aa6;

    }

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.shapesButtonActive {
    background-color: #337aa6;
}

.buttonMove {
    &::before {
        background-image: url('../../../img/move.svg');
    }
}

.buttonMoveArrow {
    &::before {
        background-image: url('../../../img/move.svg');
    }
}

.buttonHand {
    &::before {
        background-image: url('../../../img/hand.svg');
    }
}

.buttonLine {
    &::before {
        background-image: url('../../../img/line.svg');
    }
}

.buttonCircle {
    &::before {
        background-image: url('../../../img/circle.svg');
    }
}

.buttonRectangle {
    &::before {
        background-image: url('../../../img/rectangle.svg');
    }
}

.buttonPolygon {
    &::before {
        background-image: url('../../../img/polygon.svg');
    }
}

.buttonRectangle2 {
    &::before {
        background-image: url('../../../img/rectangle2.svg');
    }
}

.buttonIV {
    &::before {
        background-image: url('../../../img/IV.svg');
    }

}

.shapesMoreMoveButtons {
    left: 48px;
    top: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
}

.shapesMoreTriangleButtons {
    left: 48px;
    top: 120px;
    position: absolute;
    display: flex;
    flex-direction: column;
}

.shapesMoreNumberButtons {
    left: 48px;
    position: absolute;
    display: flex;
    flex-direction: column;
}

.buttonPolygonTop {
    &::before {
        background-image: url('../../../img/polygon.svg');
    }
}

.buttonPolygonBottom {
    &::before {
        background-image: url('../../../img/polygonBottom.svg');
    }
}

.buttonPolygonLeft {
    &::before {
        background-image: url('../../../img/polygonLeft.svg');
    }
}

.buttonPolygonRight {
    &::before {
        background-image: url('../../../img/polygonRigth.svg');
    }
}

.buttonNumberOne {
    &::before {
        background-image: url('../../../img/I.svg');
    }
}

.buttonNumberTwo {
    &::before {
        background-image: url('../../../img/II.svg');
    }
}

.buttonNumberThree {
    &::before {
        background-image: url('../../../img/III.svg');
    }
}

.buttonNumberFour {
    &::before {
        background-image: url('../../../img/IV.svg');
    }
}

.buttonNumberFive {
    &::before {
        background-image: url('../../../img/V.svg');
    }
}

.buttonNumberSix {
    &::before {
        background-image: url('../../../img/VI.svg');
    }
}

.buttonNumberSeven {
    &::before {
        background-image: url('../../../img/VII.svg');
    }
}

.buttonNumberEight {
    &::before {
        background-image: url('../../../img/VIII.svg');
    }
}

.buttonNumberNine {
    &::before {
        background-image: url('../../../img/IX.svg');
    }
}

.buttonNumberTen {
    &::before {
        background-image: url('../../../img/X.svg');
    }
}

.buttonAfter {
    &::after {
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        bottom: 40%;
        right: 0;
        width: 11px;
        height: 10px;
        background-image: url('../../../img/vectorIconBlue.svg');
    }
}

.buttonAfterActive {
    &::after {
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        bottom: 40%;
        right: 0;
        width: 11px;
        height: 10px;
        background-image: url('../../../img/VectorIconWhite.svg');
    }
}

.buttonArabicNumberOne {
    &::before {
        background-image: url('../../../img/1.svg');
    }
}

.buttonArabicNumberTwo {
    &::before {
        background-image: url('../../../img/2.svg');
    }
}

.buttonArabicNumberThree {
    &::before {
        background-image: url('../../../img/3.svg');
    }
}

.buttonArabicNumberFour {
    &::before {
        background-image: url('../../../img/4.svg');
    }
}

.buttonArabicNumberFive {
    &::before {
        background-image: url('../../../img/5.svg');
    }
}

.buttonArabicNumberSix {
    &::before {
        background-image: url('../../../img/6.svg');
    }
}

.buttonArabicNumberSeven {
    &::before {
        background-image: url('../../../img/7.svg');
    }
}

.buttonArabicNumberEight {
    &::before {
        background-image: url('../../../img/8.svg');
    }
}

.buttonArabicNumberNine {
    &::before {
        background-image: url('../../../img/9.svg');
    }
}

.buttonArabicNumberZero {
    &::before {
        background-image: url('../../../img/0.svg');
    }
}
