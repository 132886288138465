.notificationsSectionInLib {
    position: absolute;
    display: flex;
    top: 0;
    height: 32px;
    left: 0px;
    width: 100%;
    background-color: #FF5050;
    z-index: 10;

    @media (max-width: 1019px) {
        top: 44px
    }
}

.notificationsActiveLink {
    position: absolute;
    display: flex;
    top: 108px;
    min-height: 32px;
    background-color: #FF5050;
    z-index: 5;
    width: calc(100% - 664px);
    left: 414.5px;
}

.notificationsNotActiveLink {
    position: absolute;
    display: flex;
    top: 108px;
    min-height: 32px;
    background-color: #FF5050;
    z-index: 5;
    width: calc(100% - 330px);
    left: 80px;
}

@mixin notification_img {
    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    background-color: transparent;
    top: 50%;
    right: 23px;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    cursor: pointer;
    border: none;
}

.notification_close-btn {
    @include notification_img;
    background-image: url('../../../img/close-notification.svg');
}
