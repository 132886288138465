.canvas {
    min-width: 880px;//960px; // 220
    min-height: 660px;//540px; // 210
    max-width: 880px;//960px;
    max-height: 660px;
    background-color: #ccc;
    position: relative;
    margin-top: 50px; //50
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url('../../../../img/device-ui.svg');
    border-radius: 210px;
    // position: relative;
    // overflow: scroll;
}

.canvas-disabled {
    display: none;
}

#svgCanvas {
    width: 600px;//960px;
    height: 450px;//540px;
    background-color: #fff;
    // overflow: auto;
}

// .circle,
// .line,
// .triangle,
// .square,
// .rectangle {
//     cursor: default;
// }

// .selected {
//     stroke: #1a5fb4;
// }

// .marker {
//     cursor: move;
// }

.inZoom {
    cursor: grab;
}

.grab-canvas {
    cursor: grabbing;
}
