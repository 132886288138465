.modal-card {
    position: relative;
    list-style: none;
    background-color: #292A32;
    width: 340px;
    height: 432px;

    &:not(:last-child) {
        margin-right: 88px;
    }
}
.modal-card:hover {
    box-shadow: 0 8px 16px 0 rgba(219, 208, 208, 0.2);
}

.modal-card__body {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
}
