.navigation-section {
    width: 100%;
    min-height: 156px;
    margin-bottom: 10px;
}

.navigation-section_disabled {
    display: none;
}

.navigation-title {
    text-align: center;
    text-transform: uppercase;
}

.navigation-block {
    width: 190px;
    height: 156px;
    background-color: #383844;
    border-radius: 12px;
    padding: 18px 17px;
    box-sizing: border-box;
    margin: 0 auto;
}

.navigation-svg_block {
    width: 100%;
    height: 100%;
    background-color: #fff;
}

.navigation-svg_pic {
    width: 120px;
    height: 120px;
}

.navigation-title {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
}