.libraries-block {
    display: flex;
    position: relative;
}

.libraries_nav-block {
    padding-left: 16px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    width: 259px;

    @media (max-width: 1019px) {
        display: none;
    }
}

.libraries_burgerButton {
    position: fixed;
    width: 171px;
    height: 40px;
    background-color: #383844;
    border: none;
    box-sizing: border-box;
    border-radius: 24px;
    color: #E5E5E5;
    cursor: pointer;
    // text-align: start;
    bottom: 24px;
    padding-left: 25px;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 4;

    @media (min-width: 1020px) {
        display: none;
    }
}

.libraries_burgerIcon {
    &::after {
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        width: 18px;
        height: 12px;
        bottom: 33%;
        left: 25px;
        background-image: url('../../../img/burgerIcon.svg');
    }
}

.libraries_navButton {
    position: relative;
    width: 219px;
    height: 40px;
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    color: #E5E5E5;
    text-align: start;
    font-size: 16px;

    &::after {
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        width: 24px;
        height: 24px;
    }

    &:hover {
        background-color: #337aa6;
    }
}

.libraries_navButton-active {
    background-color: #337aa6;
}

.libraries_navButton-list {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0
}

.libraries_navButton-primary {
    padding-left: 64px;
    margin-bottom: 3px;
}

.libraries_navButton-secondary {
    padding-left: 92px;
    margin-bottom: 3px;
}

.libraries_navButton {}

.button_myLibIcon {
    &::after {
        bottom: 20%;
        left: 25px;
        background-image: url('../../../img/publicLibIcon.svg');
    }
}

.button_publicLibIcon {
    &::after {
        bottom: 20%;
        left: 25px;
        background-image: url('../../../img/myLibIcon.svg');
    }
}

.button_moderatorLibIcon {
    &::after {
        bottom: 20%;
        left: 25px;
        background-image: url('../../../img/moderatorViewButtonIcon.svg');
    }
}

.secondaryButton_publicLibIcon {
    &::after {
        bottom: 20%;
        left: 50px;
        background-image: url('../../../img/pubLibSecondaryIcon.svg');
    }
}

.secondaryButton_openFolderIcon {
    &::after {
        bottom: 20%;
        left: 50px;
        background-image: url('../../../img/openFolderIcon.svg');
    }
}

.libraries_card-section {
    position: relative;
    width: 100%;
}

.libraries_card-section-openCard {
    padding: 0;
}

.libraries_card-section-closeCard {
    padding: 16px 12px 12px 12px;

    @media (max-width: 1019px) {
        padding-top: 44px;
    }
}

.libraries_filterButton {
    position: relative;
    width: 80px;
    height: 40px;
    padding-left: 30px;
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;

    &::after {
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        width: 24px;
        height: 24px;
        bottom: 20%;
        left: 0;
        background-image: url('../../../img/sortIcon.svg');
    }
}

.libraries_filterButton-active {
    &::after {
        background-image: url('../../../img/filtersIconActive.svg');
        width: 24px;
        height: 24px;
    }
}

.mobile_class {
    @media (min-width: 1020px) {
        display: none;
    }
}

.search__input {
    position: relative;
    box-sizing: border-box;
    width: 540px;
    // margin-left: 13px;
    margin-bottom: 29px;
    padding-left: 26px;
    border: 1px solid #3F414B;
    border-radius: 16px;
    color: #fff;
    height: 40px;
    font-size: 16px;

    background: url('../../../img/search-icon.svg') no-repeat 8px center;
    background-color: transparent;

    &:focus {
        outline: 0;
        outline-offset: 0;
        border: 1px solid #337AA6;
        background-color: #383844;
    }

    @media (max-width: 1019px) {
        margin-bottom: 0;
        width: 696px;
    }

    @media (max-width: 767px) {
        margin-bottom: 0;
        width: 550px;
    }

    @media (max-width: 650px) {
        margin-bottom: 0;
        width: 450px;
    }

    @media (max-width: 505px) {
        margin-bottom: 0;
        width: 335px;
    }

    @media (max-width: 400px) {
        margin-bottom: 0;
        width: 288px;
    }

}

.search__inputIcon {
    position: absolute;
    right: 0;
    background-color: #337AA6;
    width: 56px;
    height: 40px;
    border: none;
    border-radius: 0px 16px 16px 0px;
}

.search__inputCloseIcon {
    position: absolute;
    right: 50px;
    width: 56px;
    height: 42px;
    border: none;
    background-color: transparent;
}

.libraries_card-block {
    overflow-y: auto;
}

.reticle_createCard {
    box-sizing: border-box;
    border: 2px solid #337AA6;
    border-radius: 32px;
    text-align: center;

    @media (max-width: 1019px) {
        display: none;
    }
}

.libraries_card-block-closeFilter {
    max-height: calc(100vh - 200px);

    @media (max-width: 1019px) {
        max-height: calc(100% - 10px);
    }
}

.libraries_card-block-openFilter {
    max-height: calc(100vh - 350px);
}

.libraries_collection-empty {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    text-align: center;
}

.libraries_collection-empty-title {
    font-size: 20px;
    color: #E5E5E5;
}

.libraries_reticleInfo-backBlock {
    display: flex;
    margin-bottom: 60px;
    margin-left: 20px;
    align-items: center;
}

.libraries_reticleInfo-backBtn {
    background: url('../../../img/back_btn.svg');
    background-size: cover;
    border: none;
    outline: none;
    width: 16px;
    height: 16px;
    background-color: inherit;
    background-position: center;
    cursor: pointer;
    margin-right: 10px;
}

.libraries_reticleInfo-backText {
    vertical-align: top;
    font-size: 16px;
}

.card_info-block {
    box-sizing: border-box;
    position: absolute;
    z-index: 5;
    width: 100%;
    background-color: #171719;
    margin: 0;
    padding: 15px;

    @media (max-width: 1019px) {
        height: 100dvh;
    }

    @media (min-width: 1020px) {
        height: 100%;
    }
}

.libraries__filters-block {
    display: flex;
    padding-left: 12px;
    padding-right: 12px;

    @media (max-width: 1019px) {
        display: none;
    }
}

.libraries_card-list {
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    margin: 0
}

.libraries_burger__modal-section {
    padding: 40px 40px 24px 40px;

    // @media (min-width: 1020px) {
    //     display: none;
    // }
}

.libraries_modalDCancelButton {
    background-color: transparent;
    border: none;
    color: #337AA6;
    font-size: 16px;
}

.libraries_adminFolderButton {
    position: relative;
    width: 219px;
    height: 40px;
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    border-radius: 8px;
    color: #E5E5E5;
    text-align: start;
    font-size: 16px;

    &::after {
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        width: 24px;
        height: 24px;
    }
}

.libraries_collection-empty-button {
    background-color: transparent;
    border: none;
    color: #337aa6;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
}
