@mixin targets_btn-icon {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
}

.targets_list {
	width: 335px;
	box-sizing: border-box;
	background-color: #2D2E36;
}

.targets_list-title-section {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px 8px 0 16px;
	margin-bottom: 8px;
}

.targets_list-title {
	padding: 0;
	margin: 0;
	font-size: 20px;
	line-height: 24px;
	font-weight: 500;
}

.targets_list-title-btn {
	background-color: transparent;
	border: none;
	color: #fff;
	padding-left: 19px;
	position: relative;
	cursor: pointer;

	&::before {
		@include targets_btn-icon;

		background-image: url('../../../../img/publish/targets_list-title-btn.svg');
	}
}
