.canvas-section {
    width: 100%;
    padding: 1%;
    padding-top: 100px;
    background-color: #171719;
    overflow-x: auto;
    overflow-y: auto;
/*    position: relative;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
