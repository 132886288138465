@mixin clear-icon {
    content: '';
    display: block;
    width: 19px;
    height: 22px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 11px;
    left: 50%;
    transform: translateX(-50%);
}

.duplicate-section {
    // margin-right: 30px;
}

.duplicate-btn {
    position: relative;
    background-color: transparent;
    border: 2px solid transparent;
    width: 100%;
    height: 100%;
    color: #fff;
    cursor: pointer;
    padding: 0;

    &:hover {
        background-color: #131318;
        border: 2px solid #337AA6;
    }

    &::before {
        @include clear-icon;
        background-image: url('../../../img/duplicate-btn-ico.svg');
    }
}

.duplicate_text {
    display: inline-block;
    padding-top: 28px;
}
