@mixin targets_btn-icon {
	content: '';
	display: block;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	position: absolute;
	top: 24px;
	left: 50%;
	transform: translateX(-50%);
	width: 24px;
	height: 24px;
}

.targets_menu-section {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 80px;
	background-color: #1f1f26;
	// padding-bottom: 16px;
	box-sizing: border-box;
}

.targets_menu-btn {
	width: 100%;
	cursor: pointer;
	background-color: transparent;
	height: 96px;
	border: none;
	color: #fff;
	padding-top: 52px;
	position: relative;
	border: 2px solid transparent;


	&:hover {
		// background-color: #131318;
		border: 2px solid #337AA6;
	}
}

.targets_menu-targets-btn {

	&::before {
		@include targets_btn-icon;

		background-image: url('../../../../img/publish/targets-btn.svg');
	}
}

.targets_menu-scene-btn {

	&::before {
		@include targets_btn-icon;

		background-image: url('../../../../img/scene-btn.svg');
	}
}

.targets_menu-scene-btn-active {

	&::before {
		@include targets_btn-icon;

		background-image: url('../../../../img/scene-btn-active.svg');
	}
}

.targets-btn_active {
	background-color: #131318;
}
