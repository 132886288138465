.preview_return-link {
	margin-right: 16px;
}

.preview_return-btn {
	background-color: transparent;
	border: 2px solid #337aa6;
	width: 150px;
	height: 40px;
	border-radius: 8px;
	box-sizing: border-box;
	cursor: pointer;
	color: #fff;

	&:hover {
		background-color: #225A7C;
		border: 1px solid #225A7C;
	}

	&:active {
		background-color: #16405A;
		border: 1px solid #16405A;
	}
}
