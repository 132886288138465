.canvas-block {
    width: 600px;
    height: 450px;
    background-color: #fff;
    overflow: auto;
}

// .circle, .line, .triangle, .square, .rectangle, .number-group {
//     cursor: default;
// }

// .selected {
//     stroke: #1a5fb4;
// }

// .marker {
//     cursor: move;
// }

.all-resize {
    cursor: move;
}

.rotate-marker {
    cursor: move;
}

.mouseSelect {
    cursor: default;
}

.left-diagonal-resize {
    cursor: nwse-resize;
}

.horizontal-resize {
    cursor: ns-resize;
}

.right-diagonal-resize {
    cursor: nesw-resize;
}

.vertical-resize {
    cursor: ew-resize;
}

.inZoom {
    cursor: grab;
}

.grab-canvas {
    cursor: grabbing;
}

.patRec {
    background-color: aqua;
}
