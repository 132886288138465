@mixin button-icon {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 21px;
    transform: translateY(-50%);
}

.importing_section {
    min-width: 400px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.preview-btn {
    width: 80px;
    height: 40px;
    left: 0px;
    top: 0px;
    border: 2px solid #337aa6;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: transparent;
    color: #fff;
    cursor: pointer;

    &:hover {
        background-color: #225A7C;
        border: 1px solid #225A7C;
    }

    &:active {
        background-color: #16405A;
        border: 1px solid #16405A;
    }
}

.publish_btn {
    width: 80px;
    height: 40px;
    left: 0px;
    top: 0px;
    border: 2px solid #337aa6;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: transparent;
    color: #fff;
    cursor: pointer;

    &:hover {
        background-color: #225A7C;
        border: 1px solid #225A7C;
    }

    &:active {
        background-color: #16405A;
        border: 1px solid #16405A;
    }
}

.export_btn {
    height: 40px;
    background-color: #337aa6;
    border: 1px solid #337aa6;
    box-sizing: border-box;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;

    &:hover {
        background-color: #225A7C;
        border: 1px solid #225A7C;
    }

    &:active {
        background-color: #16405A;
        border: 1px solid #16405A;
    }
}

.reticle_button {
    width: 129px;
    height: 40px;
    background-color: transparent;
    border: 2px solid #337aa6;
    box-sizing: border-box;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    position: relative;
    padding-left: 35px;
    margin-right: 40px;
}

.reticle_button::before {
    @include button-icon;
    width: 14px;
    height: 14px;
    background-image: url('../../../img/plus.svg');
}
