$active_shape-color: #1998EE;

@mixin tools-icons {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
}

.tools_section {
    background-color: #373841;
    display: flex;
}

.shapes_section {
    height: 64px;
    // min-width: 350px;
    // display: flex;
    // padding-left: 415px;
}

.shapes-block {
    cursor: pointer;
    width: 64px;
    padding: 8px 0;
    position: relative;
    background-color: transparent;
    border: none;
    color: #fff;
}

.button_text {
    display: inline-block;
    padding-top: 28px;
}

.shapes-block:hover {
    background-color: $active_shape-color;
}

.move::before {
    @include tools-icons;
    background-image: url('../../../img/move.svg');
}

.circle::before {
    @include tools-icons;
    background-image: url('../../../img/circle.svg');
}

.line::before {
    @include tools-icons;
    background-image: url('../../../img/line.svg');
}

.simpleLine::before {
    @include tools-icons;
    background-image: url('../../../img/line.svg');
}

.active_btn {
    background-color: $active_shape-color;
}
