.main_block {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
    width: 100%;
    height: calc(100vh - 108px);
    background-color: #2D2E36;
}

.main_block-lib {
    display: flex;
    flex-direction: row;
    // justify-content: space-evenly;
    align-items: stretch;
    width: 100%;
    height: calc(100vh - 108px);
    background-color: #171719;

    @media (max-width: 1019px) {
        height: 100%;
    }
}
