@mixin clear-icon {
    content: '';
    display: block;
    width: 16px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 13px;
    left: 50%;
    transform: translateX(-50%);
}

.tools_section {
    display: flex;
    padding-right: 24px;
    padding-left: 24px;
    background-color: #1f1f26;
    height: 64px;

    @media (max-width: 1019px) {
        display: none;
    }
}

.mesure_edit-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: nowrap;
}

.command-btns_section {
    display: flex;
    justify-content: space-between;
}

.selectedBackBtn {
    position: relative;
    background-color: transparent;
    border: 2px solid transparent;
    width: 60px;
    height: 100%;
    color: #fff;
    cursor: pointer;
    padding: 0;

    &:hover {
        background-color: #131318;
        border: 2px solid #337AA6;
    }

    &::before {
        @include clear-icon;
        background-image: url('../../img/back_btn.svg');
    }
}
