html {
    font-family: 'Roboto', "Sans serif";
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    width:  100%;
    height: 100%;
}

body {
    height: 100%;
    /*width: 100%;*/
    /* min-width: 1790px; */
    /* max-width: 1920px; */
}

.modal-container {
    position: relative;
    height: 100%;
}

a {
    text-decoration: none;
}
