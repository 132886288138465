$text-color: #e6e6e6;

@mixin preset_btn-icon {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.presets_section-title {
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 21px;
}

.presets_buttons {
    padding-bottom: 17px;
    // margin-bottom: 24px;
    border-bottom: 0.5px solid $text-color;
    display: flex;
    justify-content: space-around;
}

.preset_button {
    width: 48px;
    height: 48px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: relative;
    // padding: 0;

    &:hover {
        border: 2px solid #337AA6;
    }
}

.preset_button-active {
    background-color: #337AA6;
}

.preset_button-circle-dot {
    &::before {
        @include preset_btn-icon;
        width: 8px;
        height: 8px;
        background-image: url('../../../img/preset-circle-dot.svg');
    }
}

// .preset_button-circle-dot-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 8px;
//         height: 8px;
//         background-image: url('../../../img/preset-circle-dot-active.svg');
//     }
// }

.preset_button-circle-s {
    &::before {
        @include preset_btn-icon;
        width: 21px;
        height: 21px;
        background-image: url('../../../img/preset-circle-s.svg');
    }
}

// .preset_button-circle-s-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 21px;
//         height: 21px;
//         background-image: url('../../../img/preset-circle-s-active.svg');
//     }
// }

.preset_button-circle-m {
    &::before {
        @include preset_btn-icon;
        width: 31px;
        height: 31px;
        background-image: url('../../../img/preset-circle-m.svg');
    }
}

// .preset_button-circle-m-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 31px;
//         height: 31px;
//         background-image: url('../../../img/preset-circle-m-active.svg');
//     }
// }

.preset_button-circle-l {
    &::before {
        @include preset_btn-icon;
        width: 42px;
        height: 42px;
        background-image: url('../../../img/preset-circle-l.svg');
    }
}

// .preset_button-circle-l-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 42px;
//         height: 42px;
//         background-image: url('../../../img/preset-circle-l-active.svg');
//     }
// }

.preset_button-square-s {
    &::before {
        @include preset_btn-icon;
        width: 21px;
        height: 21px;
        background-image: url('../../../img/preset-square-s.svg');
    }
}

// .preset_button-square-s-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 21px;
//         height: 21px;
//         background-image: url('../../../img/preset-square-s-active.svg');
//     }
// }

.preset_button-square-m {
    &::before {
        @include preset_btn-icon;
        width: 31px;
        height: 31px;
        background-image: url('../../../img/preset-square-m.svg');
    }
}

// .preset_button-square-m-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 31px;
//         height: 31px;
//         background-image: url('../../../img/preset-square-m-active.svg');
//     }
// }

.preset_button-square-l {
    &::before {
        @include preset_btn-icon;
        width: 42px;
        height: 42px;
        background-image: url('../../../img/preset-square-l.svg');
    }
}

// .preset_button-square-l-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 42px;
//         height: 42px;
//         background-image: url('../../../img/preset-square-l-active.svg');
//     }
// }

.preset_button-triangle-s,
.preset_button-triangle-top-s {
    &::before {
        @include preset_btn-icon;
        width: 21px;
        height: 21px;
        background-image: url('../../../img/preset-triangle-s.svg');
    }
}

// .preset_button-triangle-s-active,
// .preset_button-triangle-top-s-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 21px;
//         height: 21px;
//         background-image: url('../../../img/preset-triangle-s-active.svg');
//     }
// }

.preset_button-triangle-m,
.preset_button-triangle-top-m {
    &::before {
        @include preset_btn-icon;
        width: 31px;
        height: 31px;
        background-image: url('../../../img/preset-triangle-m.svg');
    }
}

// .preset_button-triangle-m-active,
// .preset_button-triangle-top-m-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 31px;
//         height: 31px;
//         background-image: url('../../../img/preset-triangle-m-active.svg');
//     }
// }

.preset_button-triangle-l,
.preset_button-triangle-top-l {
    &::before {
        @include preset_btn-icon;
        width: 42px;
        height: 42px;
        background-image: url('../../../img/preset-triangle-l.svg');
    }
}

// .preset_button-triangle-l-active,
// .preset_button-triangle-top-l-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 42px;
//         height: 42px;
//         background-image: url('../../../img/preset-triangle-l-active.svg');
//     }
// }

.preset_button-triangle-right-s {
    &::before {
        @include preset_btn-icon;
        width: 21px;
        height: 21px;
        background-image: url('../../../img/preset-triangle-s.svg');
        transform: translateX(-50%) translateY(-50%) rotate(90deg);
    }
}

// .preset_button-triangle-right-s-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 21px;
//         height: 21px;
//         background-image: url('../../../img/preset-triangle-s-active.svg');
//         transform: rotate(90deg);
//         left: 0;
//     }
// }

.preset_button-triangle-right-m {
    &::before {
        @include preset_btn-icon;
        width: 31px;
        height: 31px;
        background-image: url('../../../img/preset-triangle-s.svg');
        transform: translateX(-50%) translateY(-50%) rotate(90deg);
    }
}

// .preset_button-triangle-right-m-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 31px;
//         height: 31px;
//         background-image: url('../../../img/preset-triangle-s-active.svg');
//         transform: rotate(90deg);
//         left: 0;
//     }
// }

.preset_button-triangle-right-l {
    &::before {
        @include preset_btn-icon;
        width: 42px;
        height: 42px;
        background-image: url('../../../img/preset-triangle-s.svg');
        transform: translateX(-50%) translateY(-50%) rotate(90deg);
    }
}

// .preset_button-triangle-right-l-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 42px;
//         height: 42px;
//         background-image: url('../../../img/preset-triangle-s-active.svg');
//         transform: rotate(90deg);
//         left: 0;
//     }
// }

.preset_button-triangle-bottom-s {
    &::before {
        @include preset_btn-icon;
        width: 21px;
        height: 21px;
        background-image: url('../../../img/preset-triangle-s.svg');
        transform: translateX(-50%) translateY(-50%) rotate(180deg);
    }
}

// .preset_button-triangle-bottom-s-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 21px;
//         height: 21px;
//         background-image: url('../../../img/preset-triangle-s-active.svg');
//         transform: rotate(180deg);
//         left: 0;
//     }
// }

.preset_button-triangle-bottom-m {
    &::before {
        @include preset_btn-icon;
        width: 31px;
        height: 31px;
        background-image: url('../../../img/preset-triangle-s.svg');
        transform: translateX(-50%) translateY(-50%) rotate(180deg);
    }
}

// .preset_button-triangle-bottom-m-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 31px;
//         height: 31px;
//         background-image: url('../../../img/preset-triangle-s-active.svg');
//         transform: rotate(180deg);
//         left: 0;
//     }
// }

.preset_button-triangle-bottom-l {
    &::before {
        @include preset_btn-icon;
        width: 42px;
        height: 42px;
        background-image: url('../../../img/preset-triangle-s.svg');
        transform: translateX(-50%) translateY(-50%) rotate(180deg);
    }
}

// .preset_button-triangle-bottom-l-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 42px;
//         height: 42px;
//         background-image: url('../../../img/preset-triangle-s-active.svg');
//         transform: rotate(180deg);
//         left: 0;
//     }
// }

.preset_button-triangle-left-s {
    &::before {
        @include preset_btn-icon;
        width: 21px;
        height: 21px;
        background-image: url('../../../img/preset-triangle-s.svg');
        transform: translateX(-50%) translateY(-50%) rotate(270deg);
    }
}

// .preset_button-triangle-left-s-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 21px;
//         height: 21px;
//         background-image: url('../../../img/preset-triangle-s-active.svg');
//         transform: rotate(270deg);
//         left: 0;
//     }
// }

.preset_button-triangle-left-m {
    &::before {
        @include preset_btn-icon;
        width: 31px;
        height: 31px;
        background-image: url('../../../img/preset-triangle-s.svg');
        transform: translateX(-50%) translateY(-50%) rotate(270deg);
    }
}

// .preset_button-triangle-left-m-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 31px;
//         height: 31px;
//         background-image: url('../../../img/preset-triangle-s-active.svg');
//         transform: rotate(270deg);
//         left: 0;
//     }
// }

.preset_button-triangle-left-l {
    &::before {
        @include preset_btn-icon;
        width: 42px;
        height: 42px;
        background-image: url('../../../img/preset-triangle-s.svg');
        transform: translateX(-50%) translateY(-50%) rotate(270deg);
    }
}

// .preset_button-triangle-left-l-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 42px;
//         height: 42px;
//         background-image: url('../../../img/preset-triangle-s-active.svg');
//         transform: rotate(270deg);
//         left: 0;
//     }
// }

.preset_button-rectangle-s {
    &::before {
        @include preset_btn-icon;
        width: 21px;
        height: 21px;
        background-image: url('../../../img/preset-rectangle-s.svg');
    }
}

// .preset_button-rectangle-s-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 21px;
//         height: 21px;
//         background-image: url('../../../img/preset-rectangle-s-active.svg');
//     }
// }

.preset_button-rectangle-m {
    &::before {
        @include preset_btn-icon;
        width: 31px;
        height: 31px;
        background-image: url('../../../img/preset-rectangle-m.svg');
    }
}

// .preset_button-rectangle-m-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 31px;
//         height: 31px;
//         background-image: url('../../../img/preset-rectangle-m-active.svg');
//     }
// }

.preset_button-rectangle-l {
    &::before {
        @include preset_btn-icon;
        width: 42px;
        height: 42px;
        background-image: url('../../../img/preset-rectangle-l.svg');
    }
}

// .preset_button-rectangle-l-active {
//     &::before {
//         @include preset_btn-icon;
//         width: 42px;
//         height: 42px;
//         background-image: url('../../../img/preset-rectangle-l-active.svg');
//     }
// }

.presets_dimensions {
    border-bottom: 0.5px solid $text-color;
    padding-bottom: 24px;
    margin-top: 24px;
}

.dimensions_input {
    width: 54px;
    height: 24px;
    box-sizing: border-box;
    background-color: #292a32;
    border: 1px solid #383844;
    border-radius: 2px;
    color: $text-color;
    text-align: center;
    margin-right: 8px;
}

.dimension-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }
}

.dimension-text {
    margin: 0 16px 0 0;
    width: 50px;
    font-size: 16px;
    line-height: 24px;
}

.dimension-text-grid {
    text-transform: uppercase;
}

.preset-disabled-s,
.preset-disabled-m,
.preset-disabled-l {
    display: none;
}

.rotate-block {
    display: flex;
    align-items: center;
}

.rotate-paragraph {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    margin-right: 16px;
}

.rotate-button {
    position: relative;
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-right: 8px;

    &::before {
        @include preset_btn-icon;
        width: 18px;
        height: 21px;
    }
}

.rotate-btn_plus {
    &::before {
        @include preset_btn-icon;
        background-image: url('../../../img/rotate-plus-icon.svg');
    }
}

.rotate-btn_minus {
    &::before {
        @include preset_btn-icon;
        background-image: url('../../../img/rotate-minus-icon.svg');
    }
}
