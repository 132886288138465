.version-section {
    // display: inline-block;
    // position: absolute;
    // bottom: 5px;
    // right: 16px;
    text-align: right;
}

.version_text {
    margin: 0;
    padding: 0;
    font-size: 10px;
    line-height: 10px;
    display: inline-block;
    // position: absolute;
    // right: 16px;
}
