.preview_devices-section {
    display: block;
    font-family: 'Roboto';
    line-height: 24px;
    font-size: 16px;
    position: relative;
}

.preview_devices-block {
    display: flex;
    position: relative;
}

.preview_devices-button {
    background-color: transparent;
    border: none;
    color: #fff;
    padding-right: 28px;
    position: relative;
    cursor: pointer;

    &::before {
		content: '';
		display: block;
		background-repeat: no-repeat;
		background-position: center;
		position: absolute;
		top: 50%;
		right: 6px;
		transform: translateY(-50%);
		width: 12px;
		height: 7px;
        background-image: url('../../../assets/img/devices-btn-ico.svg');
	}
}

.devices_button-txt {
    border-bottom: 1px solid #337aa6;
}

.devices_menu-block {
    position: absolute;
    padding: 16px 25px;
    top: 57px;
    right: -50%;
    transform: translateX(-50%);
    width: 248px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #080808;
    z-index: 999;

    &::before {
		content: '';
		display: block;
		background-repeat: no-repeat;
		background-position: center;
		position: absolute;
		top: -8px;
		left: 50%;
		transform: translateX(-50%);
		width: 8px;
		height: 8px;
        background-image: url('../../../assets/img/preview-devices-menu-poly.svg');
	}
}

.devices_menu-block-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.devices_menu-block-paragraph {
    margin: 0;
    margin-bottom: 8px;
    text-align: center;
}

.devices_menu-block-input {
    margin-right: 8px;
    height: 36px;
    display: none;
}

.devices_menu-block-label {
    display: inline-block;
    flex-grow: 1;
}

.devices_menu-block-input+label::before {
    content: '';
    display: inline-block;
    width: 11.73px;
    height: 8.93px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 10px;
}

.devices_menu-block-input:checked+label::before {
    background-image: url('../../img/grid-size-select.svg');
}