/* Wrapping */
.Tooltip-Wrapper {
  display: inline-block;
  z-index: 100;

}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  position: absolute;
  border-radius: 8px;
  right: calc(100% - 180px);
  padding: 16px 10px;
  background: var(--tooltip-background-color);
  z-index: 100;
}

/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}
