.edit-section {
    max-width: 250px;
    min-width: 250px;
    padding: 16px;
    background-color: #131318;
    box-sizing: border-box;
    position: relative;
    overflow-x: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.edit-section_wrapper {
    flex: 1 1 auto;
    margin-bottom: 5px;
}
