.reticleCardInfo {
    position: relative;
    list-style: none;
    width: 100%;
    margin-top: 80px;
}

.reticleCardInfo__title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
    color: #E5E5E5;
}

.reticleCardInfo__text {
    margin: 0;
    font-size: 16px;
    color: #AAAAAA;
}

.reticleCardInfo__description {
    background-color: #383844;
    padding: 16px;
    border-radius: 24px;
    color: #E5E5E5;
    // margin-bottom: 24px;
}

.reticleCardInfo__description-button {
    background-color: transparent;
    color: #FFFFFF;
    border: none;
    padding: 0;
}

.reticleCardInfo__tags {
    height: 36px;
    background-color: #383844;
    margin-right: 5px;
    margin-bottom: 5px;
    border: none;
    border-radius: 24px;
    color: #AAAAAA;
    padding: 8px 16px;
}

.reticleCardInfo__container {
    padding: 8px 0;
}

.reticleCardInfo__info {
    width: 100%
}

.reticleCardInfo__body-vsg {
    height: 350px;
    border-radius: 32px;
}

.reticleCardInfo__body-vsg>svg {
    width: 100%;
    height: 100%;
    background-color: #e5e5e5;
    border-radius: 32px;
}

.reticleCardInfo__body-vsg>svg#svgCanvas {
    width: 100%;
    height: 100%;
    background-color: #e5e5e5;
    border-radius: 32px;
}

.reticleCardInfo__button {
    height: 40px;
    border: 1px solid #337AA6;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: transparent;
    color: #E5E5E5;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: #225A7C;
        border: 1px solid #225A7C;
    }

    &:active {
        background-color: #16405A;
        border: 1px solid #16405A;
    }

    &:not(:last-child) {
        margin-right: 10px;
    }
}

.reticleCardInfo__button-desktop {
    display: none;

    @media (min-width: 1020px) {
        display: flex;
        align-items: center
    }
}

.reticleCardInfo__button-active {
    background-color: #337aa6;

    &:hover {
        background-color: #225A7C;
        border: 1px solid #225A7C;
    }

    &:active {
        background-color: #16405A;
        border: 1px solid #16405A;
    }
}

.reticleCardInfo__button-disabled {
    height: 40px;
    box-sizing: border-box;
    border-radius: 8px;
    color: #E5E5E5;
    display: flex;
    align-items: center;
    background-color: #e5e5e5;
    margin-right: 10px;
}

.reticleCardInfo__button-delete {
    background-color: transparent;
    border: 1px solid #FF5050;
    color: #FF5050;
    // display: flex;
    // align-items: center;

    &:hover {
        background-color: #FF5050;
        border: 1px solid #FF5050;
        color: #E5E5E5;

        &.reticleCardInfo__button-delete>svg>path {
            fill: #E5E5E5;
        }
    }

    &:active {
        background-color: #FF1A1A;
        border: 1px solid #FF1A1A;
        color: #E5E5E5;

        &.reticleCardInfo__button-delete>svg>path {
            fill: #E5E5E5;
        }
    }
}


.reticleCardInfo__button-icon {
    vertical-align: text-bottom;
    margin-right: 8px
}

.reticleCardInfo__switch {
    position: relative;
    display: inline-block;
    border: 2px solid #fff;
    border-radius: 10px;
    width: 22px;
    height: 12px;
}

.reticleCardInfo__switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.reticleCardInfo__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
}

.reticleCardInfo__slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 1px;
    top: 1px;
    background-color: #337AA6;
    transition: .3s;
}

input:checked+.reticleCardInfo__slider:before {
    transform: translateX(10px);
}

.reticleCardInfo__slider.reticleCardInfo__round:before {
    border-radius: 50%;
}

.reticleCardInfo__checked_label {
    text-transform: uppercase;
    margin-right: 8px;
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
}

.reticleCardInfo__actions-block {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 24px;
}

.reticleCardInfo__switch-block {
    display: flex;
    align-items: center;
    width: 271px;
    justify-content: space-between
}

.reticleCardInfo__actionButton-block {
    @media (max-width: 1019px) {
        margin-left: auto;
    }
}

.reticleCardInfo__switch-block-mobVersion {
    display: flex;
    position: absolute;
    align-items: center;
    width: 271px;
    justify-content: space-between;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.reticleCardInfo__notApproved-block-mobVersion {
    display: flex;
    position: absolute;
    align-items: center;
    width: 413px;
    justify-content: space-between;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 767px) {
        width: 290px;
    }
}

.reticleCardInfo_ban-block-mobVersion {
    display: flex;
    position: absolute;
    align-items: center;
    // width: 413px;
    justify-content: space-between;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.reticleCardInfo__saveNotification-block {
    position: absolute;
    background-color: #383844;
    border-radius: 24px;
    width: 540px;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    bottom: 20px;
    left: 35%;
    transform: translateX(-35%);
    z-index: 5;

    @media (max-width: 1019px) {
        width: 540px;
        left: 50%;
        transform: translateX(-50%);
    }

    @media (max-width: 767px) {
        width: 316px;
        left: 50%;
        transform: translateX(-50%);
    }

}

.reticleCardInfo__saveNotification-text {
    @media (max-width: 1019px) {
        text-align: center;
    }

    @media (max-width: 767px) {
        width: 252px;
    }
}

.reticleCardInfo__modal-section {
    padding: 32px
}

.reticleCardInfo__modalShareButton-block {
    display: flex;
    justify-content: space-evenly
}

.reticleCardInfo__modalShareInput {
    box-sizing: border-box;
    width: 400px;
    height: 80px;
    background-color: #1F1F26;
    border: none;
    border-radius: 24px;
    color: white;

    padding-left: 16px;

    @media (max-width: 767px) {
        width: 270px;
    }
}

.reticleCardInfo__modalShareInputButton {
    position: absolute;
    right: 16px;
    bottom: 20px
}

.reticleCardInfo__user-photo {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 16px;
    margin-top: 10px;
}

.userAccountInput {
    resize: none;
    box-sizing: border-box;
    width: 405px;
    margin-bottom: 20px;
    margin-top: 8px;
    padding: 5px 16px;
    background-color: #292A32;
    border: none;

    border-radius: 4px;
    color: #fff;
    height: 140px;

    &:focus {
        outline: 0;
        outline-offset: 0;
        border: none;
        background-color: #292A32;
    }
}

.reticleCardInfo_folder-block {
    display: flex;
    flex-direction: column;
    width: 222px;
    margin-bottom: 10px;

    @media (max-width: 1019px) {
        display: none;
    }
}

.reticleCardInfo_banNotification {
    box-sizing: border-box;
    background-color: transparent;
    color: #E5E5E5;
    cursor: pointer;
    display: flex;
    align-items: center;
}
