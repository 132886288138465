@mixin tab_img {
    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    background-color: transparent;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    cursor: pointer;
    border: none;
}

.tab_block {
	width: 130px;
	height: 44px;
    padding: 12px 28px 12px 8px;
	box-sizing: border-box;
	background-color: #171719;
	position: relative;
	cursor: default;
	box-shadow: none;
    outline: none;
    border: none;
}

.tabs_list-style {
	margin: 0;
    display: flex;
    align-items: center;
    position: relative;
}

.active_tab {
	background-color: #373841;
}

.tab_title {
	font-size: 14px;
	line-height: 20px;
	margin: 0;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.tab_close-btn {
	@include tab_img;
	background-image: url('../../img/close-tab.svg');
}
