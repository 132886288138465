.logo_img {
    display: block;
    width: 30px;
    height: 24px;
    margin-right: 16px;

    @media (max-width: 1019px) {
        margin-right: 8px;
    }
}
