.sceneCard {
    position: relative;
    list-style: none;
    background-color: #222329;
    border: 1px solid #3F414B;
    width: 311px;
    min-height: 212px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 7px;
}

.sceneCard__body::before {
    content: '';

    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(34, 35, 41, 0.9);
    opacity: 0;
    z-index: 1;
}

.sceneCard__body:hover::before {
    opacity: 1;
}

.sceneCard__body:hover .sceneCard__body-actions {
    opacity: 1;
}

.sceneCard__body-actions {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    opacity: 0;
    z-index: 1;
}

.sceneCard__body-btn {
    width: 80px;
    height: 40px;
    left: 0px;
    top: 0px;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: transparent;
    color: #fff;
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 24px;
    }
}

.sceneCard__body-btn-disabled {
    color: #EF3B2D;
}

.sceneCard__body-btn-open {
    border: 2px solid #337aa6;

    &:hover {
        background-color: #225A7C;
        border: 1px solid #225A7C;
    }

    &:active {
        background-color: #16405A;
        border: 1px solid #16405A;
    }
}

.sceneCard__body-btn-delete {
    border: 2px solid #EF3B2D;
}

.sceneCard__body-actions-block {
    width: 80px;
}

.sceneCard__info {
    display: flex;
    justify-content: space-between;
}

.rating {
    position: relative;
    font-size: 20px;
    z-index: 0;
}

.rating::before {
    content: "☆☆☆☆☆";
    display: block;
}

.rating__active {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    color: #222329;
}

.rating__active::before {
    content: "★★★★★";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: #fff;
}

.sceneCard__title {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 14px;
}

.sceneCard__description {
    margin: 0;
    font-size: 12px;
}

.sceneCard__container {
    padding: 8px 24px;
}

.sceneCard__body-vsg {
    height: 150px;
}

.sceneCard__body-vsg>svg {
    width: 100%;
    height: 100%;
}

.sceneCard__body-vsg>svg#svgCanvas {
    width: 100%;
    height: 100%;
}
