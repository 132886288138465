.preview_menu-section {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 64px;
	background-color: #1f1f26;
	padding: 12px 15px 12px 24px;
	box-sizing: border-box;
}

.preview_menu-importing {
	display: flex;
}
