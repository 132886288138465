@mixin measure-icon {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
}

@mixin selected-icon {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    bottom: 0;
    left: 0;
}

.measure_section {
    display: flex;
}

.measure-block {
    width: 149px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    position: relative;



    &:first-child {
        padding: 0;

        &:hover {
            background-color: #131318;
        }
    }
}

.measure-btn {
    margin: 0;
    // padding: 28px 0 0 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
    color: #fff;
    border: 2px solid transparent;
    font-size: 16px;

    &:hover {
        cursor: pointer;
        border: 2px solid #337AA6;
    }

}

.measure-btn-Top {
    &::after {
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        bottom: 40%;
        right: 0;
        width: 11px;
        height: 10px;
        margin-right: 10px;
        background-image: url('../../../img/open-measure.svg');
    }
}

.measure-btn-Bottom {
    &::after {
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        bottom: 40%;
        right: 0;
        width: 11px;
        height: 10px;
        margin-right: 10px;
        background-image: url('../../../img/open-measure.svg');
    }
}

.measure-link {
    text-decoration: none;
}

.measure-preview {
    margin: 28px 0 0 0;
    color: #fff;
}

.rulers::before {
    @include measure-icon;
    width: 20px;
    height: 23.5px;
    background-image: url('../../../img/rulers.svg');
}

// .grid::before {
//     @include measure-icon;
//     width: 24px;
//     height: 24px;
//     background-image: url('../../../img/grid.svg');
// }

// .grid::after {
//     @include selected-icon;
//     width: 11px;
//     height: 10px;
//     background-image: url('../../../img/triangle.svg');
// }

.preview::before {
    @include measure-icon;
    width: 25px;
    height: 24px;
    background-image: url('../../../img/preview.svg');
}

.grid-menu {
    display: none;
    cursor: auto;
    padding: 16px 0;
    position: absolute;
    width: 248px;
    top: 100%;
    background: #080808;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgb(26 26 33 / 50%);
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
}

// .grid-block:hover {
//     .grid-menu {
//         display: block;
//     }
// }
.grid-size {
    text-align: center;
    position: relative;
}

.grid-size_input {
    color: #fff;
    width: 56px;
    height: 24px;
    background: #292A32;
    border: 1px solid #383844;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
}

.gridSizeSpan {
    position: absolute;
    top: 0;
    right: 5px;
}

.grid-size_container {
    display: inline;
    position: relative;
}

.grid-size_label {
    font-size: 16px;
    line-height: 20px;
    margin-right: 20px;
    margin-left: 20px;
}

.grid_menu-btn {
    width: 14px;
    height: 24px;
    background-color: transparent;
    border: none;
    // position: absolute;
    // left: 0;
    cursor: pointer;
    padding: 0;
}

.btn-up {
    margin-left: 9px;
}

.btn-up::before {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    // position: absolute;
    background-image: url('../../../img/btn-down.svg');
    width: 14px;
    height: 14px;
    // left: 50%;
    // top: 25%;
    // transform: translateY(-50%);
    // transform: translateX(-50%);
}

.btn-down {
    margin-right: 9px;
}

.btn-down::before {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    // position: absolute;
    background-image: url('../../../img/btn-up.svg');
    width: 14px;
    height: 14px;
    // left: 50%;
    // top: 55%;
    // transform: translateY(-50%);
    // transform: translateX(-50%);
}

.grid_item {
    list-style-type: none;
}

.grid-list {
    margin: 0;
    cursor: auto;
}

.grid_item {
    cursor: pointer;
    width: 49px;
    margin-bottom: 8px;
}

// .formunit {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
// }

.units-wrapper {
    display: flex;
    flex-direction: column;
}

.unit-legend {
    font-size: 16px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 16px;
}

.radio-wrapper {
    display: flex;
    height: 36px;
    border: 2px solid transparent;

    &:hover {
        cursor: pointer;
        border: 2px solid #337AA6;
    }
}

.custom-grid_radio {
    position: absolute;
    z-index: -1;
    opacity: 0;
    padding-left: 10px;
}

.custom-grid_label {
    cursor: pointer;
}

.grid-wrapper {
    display: flex;
    height: 36px;
    border: 2px solid transparent;

    &:hover {
        cursor: pointer;
        border: 2px solid #337AA6;
    }

    // margin-bottom: 8px;
}

// .grid-wrapper:last-child {
//     margin-bottom: 16px;
// }

.custom-grid_radio+label {
    font-size: 14px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    user-select: none;
    padding-left: 25px;
}

.custom-grid_radio+label::before {
    content: '';
    display: inline-block;
    width: 11.73px;
    height: 8.93px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 10px;
}

.custom-grid_radio:checked+label::before {
    background-image: url('../../../img/grid-size-select.svg');
}

.custom-grid-active::before {
    content: '';
    display: inline-block;
    width: 11.73px;
    height: 8.93px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url('../../../img/grid-size-select.svg');
}

// .grid-list {
//     padding: 0 0 0 17px;
// }

.custom-unit_radio {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-unit_radio-label {
    cursor: pointer;
    font-size: 16px;
}

.custom-unit_radio+label {
    font-size: 14px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    user-select: none;
    padding-left: 25px;
}

.custom-unit_radio+label::before {
    content: '';
    display: block;
    width: 13.33px;
    height: 13.33px;
    display: block;
    border: 1px solid #fff;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 10px;
}

.custom-unit_radio:checked+label::before {
    background-image: url('../../../img/radio.svg');
}

.measure-btn-active {
    background-color: #131318;

    &::before {
        @include measure-icon;
        width: 24px;
        height: 24px;
        background-image: url('../../../img/rulers-active.svg');
    }
}

.grid-block-active {
    background-color: #131318;
}

.grid-active {
    background-color: #131318;

    // &::before {
    //     @include measure-icon;
    //     width: 24px;
    //     height: 24px;
    //     background-image: url('../../../img/grid-active.svg');
    // }
}

.grid-menu-active {
    display: block;
    z-index: 100;
}
