.header {
    background-color: #171719;
    height: 44px;
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;

    @media (max-width: 1019px) {
        position: fixed;
        border-bottom: 1px solid #383844;
        justify-content: space-between;
        z-index: 7;
        padding-left: 16px;
        padding-right: 16px;
    }
}

.header_openSearch {
    @media (max-width: 1019px) {
        justify-content: space-around;
    }
}

.logo_title {
    margin: 0;
    font-weight: 500;
    line-height: 24px;

    @media (max-width: 1019px) {
        font-size: 20px;
    }
}

.header_section {
    display: flex;
    // margin-right: 26px;
    white-space: nowrap;
}

.header_backBtn {
    background: url('../../img/back_btn.svg');
    background-size: cover;
    border: none;
    outline: none;
    width: 16px;
    height: 16px;
    background-color: inherit;
    background-position: center;
    cursor: pointer;
    margin-right: 10px;

    @media (min-width: 1020px) {
        display: none;
    }
}

.header_Button {
    width: 45px;
    height: 40px;
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
}

.header_filters-block {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
}

.header__modal-section {
    padding: 24px 16px 24px 16px;
}

.dropdown {
    // z-index: 10000;
    width: 175px;
    position: relative;
    // margin-left: 10px;
}

.dropdown,
.dropdown * {
    // z-index: 10;
}

.dropdown .dropdown-btn {
    display: flex;
    box-sizing: border-box;
    cursor: pointer;
    align-items: center;
    background: transparent;
    // height: 32px;
    font-size: 16px;
    color: #E5E5E5;
    padding: 10px;
}

.dropdown-content {
    position: absolute;
    background-color: #131318;
    color: #E5E5E5;
    width: 100%;
    // height: 32px;
    z-index: 10;
}

.dropdown-content .item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    // height: 32px;
    width: 100%;

}

.vectorTop {
    &::after {
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        bottom: 32%;
        right: 16px;
        width: 11px;
        height: 10px;
        background-image: url('../../img/vector-top.svg');
    }
}

.vectorBottom {
    &::after {
        content: "";
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        bottom: 32%;
        right: 16px;
        width: 11px;
        height: 10px;
        background-image: url('../../img/vector-bottom.svg');
    }
}

.header_backText {
    @media (min-width: 1020px) {
        display: none;
    }
}
