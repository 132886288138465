@mixin input-icon {
	content: '';
	display: block;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	position: absolute;
	top: 55%;
	left: 26px;
	transform: translateY(-50%);
	width: 10px;
	height: 10px;
}

@mixin input_btn-icon {
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.lib-edit-section-block {
	width: 335px;
	background-color: #131318;
	// padding: 24px 9px 24px 16px;
	box-sizing: border-box;
}

.lib-edit-section_title {
	margin: 24px;
	// padding: 24px;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
}

.lib-edit-section_fp {
	padding-bottom: 10px;
	position: relative;
	overflow-y: auto;
	max-height: calc(100vh - 220px);
	// padding-left: 6px;
}

.lib-edit-section_fp-title {
	font-weight: 400;
	font-size: 14px;
	margin: 0 0 10px 0;
	padding-left: 26px;
	position: relative;

	&::before {
		content: '';
		display: block;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		background-image: url('../../../img/create_arrow.svg');
		width: 12px;
		height: 8px;
	}
}

// .lib-edit-section_fp {
// margin-left: 22px;
// }

.lib-edit-section_fp-list {
	padding: 0;
	margin: 0;
	width: 100%;
	list-style-type: none;
}

.lib-edit-section_fp-item {
	height: 28px;
	border: 2px solid transparent;
	// margin-bottom: 2px;
	cursor: pointer;
	// width: 100%;
	// box-sizing: border-box;
	font-size: 14px;
	line-height: 20px;
	padding-left: 2px;
	position: relative;

	&:active,
	:focus {
		outline: none;
		outline-offset: 0;
	}

	&:hover {
		border: 2px solid #337aa6;
		height: 28px;
	}
}

.lib-edit-section_fp-input {
	box-sizing: border-box;
	width: 100%;
	padding: 4px 85px 4px 48px;
	background-color: transparent;
	border: none;
	color: #fff;
	height: 100%;
	position: relative;
	vertical-align: middle;

	&:hover {
		cursor: pointer;
	}
}

.leyers_buttons-block {
	position: absolute;
	top: 0;
	right: 0;
}

.layer-btn {
	display: inline-block;
	width: 24px;
	height: 24px;
	background-color: transparent;
	border: none;
	background-repeat: no-repeat;
	background-position: center;

	&::before {
		content: '';
		display: block;
		background-repeat: no-repeat;
		background-position: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%);
		width: 16px;
		height: 16px;
	}
}

.layer_btn-up {
	background-image: url('../../../img/layer-arrow-down.svg');
}

.layer_btn-down {
	margin-right: 3px;
	background-image: url('../../../img/layer-arrow-up.svg');
}

.lib-edit-section_group {
	list-style-type: none;
	padding: 0;
}

.lib-edit-section_group-input {
	padding-left: 65px;
}

.lib-edit-section_fp-item_circle::before {
	@include input-icon;
	background-image: url('../../../img/list-marker_circle.svg');
}

.lib-edit-section_fp-item_line::before {
	@include input-icon;
	background-image: url('../../../img/list-marker_line.svg');
}

.lib-edit-section_fp-item_shape::before {
	@include input-icon;
	background-image: url('../../../img/list-marker_line.svg');
}

.lib-edit-section_fp-item_group::before {
	@include input-icon;
	background-image: url('../../../img/layer_group-ico.svg');
}

.lib-edit-section_fp-item_triangle::before {
	@include input-icon;
	background-image: url('../../../img/triangleTopIcon.svg');
}

.lib-edit-section_fp-item_square::before {
	@include input-icon;
	background-image: url('../../../img/squareIcon.svg');
}

.lib-edit-section_fp-item_rect::before {
	@include input-icon;
	background-image: url('../../../img/rectangleIcon.svg');
}

.lib-edit-section_fp-item_romNum::before {
	@include input-icon;
	background-image: url('../../../img/IVIcon.svg');
}

.lib-edit-section_fp-item_arabicNum::before {
	@include input-icon;
	background-image: url('../../../img/5Icon.svg');
}

.lib-edit-section_group-item::before {
	left: 50px;
}

.lib-edit-section_input-btn {
	position: absolute;
	top: 50%;
	right: 8px;
	transform: translateY(-50%);
	cursor: pointer;
	background-color: transparent;
	border: none;
	// display: none;
}

.input_btn-lock {
	@include input_btn-icon;
	right: 34px;
	width: 11px;
	height: 14px;
	background-image: url('../../../img/input_btn-lock.svg');
}

.input_btn-zoom {
	@include input_btn-icon;
	right: 58px;
	width: 12px;
	height: 12px;
	background-image: url('../../../img/input_btn-zoom.svg');
}

.input_btn-visibility {
	@include input_btn-icon;
	width: 15px;
	height: 10px;
	background-image: url('../../../img/input_btn-visibility.svg');
}

.list_input-active {
	background-color: #080808;
	border: 2px solid #337aa6;
}

// .input_btn-active {
// 	display: block;
// }
