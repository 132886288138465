.zoom-section {
    width: 254px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 24px;
    padding: 8px;
    display: flex;
    justify-content: space-around;
    background-color: #000;
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translateX(-30%);
}

.zoom-range {
    width: 174px;
    // background-color: #E6E6E6;
}

.zoom-btn {
    width: 24px;
    height: 24px;
    border: none;
    background-color: transparent;
    cursor: pointer;
}