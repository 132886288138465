.preview_export-section {
	display: flex;
	margin: 0;
	padding: 0;
}

.preview_section-btn {
	height: 40px;
	box-sizing: border-box;
	border-radius: 8px;
	cursor: pointer;
	background-color: transparent;
	color: #fff;

	&:hover {
		background-color: #225A7C;
		border: 1px solid #225A7C;
	}

	&:active {
		background-color: #16405A;
		border: 1px solid #16405A;
	}
}

.publish-btn {
	margin-right: 16px;
	width: 80px;
	border: 2px solid #337aa6;
}

.export-btn {
	border: 1px solid #337aa6;
	background-color: #337aa6;
	width: 139px;
	margin-left: 16px;
}
