$text-color: #e6e6e6;

@mixin stroke_btn-icon {
	content: '';
	width: 30px;
	height: 30px;
	left: 50%;
	top: 50%;
	display: block;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	position: absolute;
	transform: translateX(-50%) translateY(-50%);
}

.stroke_buttons {
	width: 220px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.stroke_button {
	width: 40px;
	height: 40px;
	background-color: transparent;
	border: none;
	cursor: pointer;
	position: relative;
	// padding: 0;

	&:hover {
		border: 2px solid #337AA6;
	}
}

.stroke_button-active {
	background-color: #337AA6;
}

.stroke_button_line1::before {
	@include stroke_btn-icon;
	background-image: url('../../../img/list-marker_line.svg');
}

.stroke_button_line2::before {
	@include stroke_btn-icon;
	background-image: url('../../../img/dashedLineIcon.svg');
}

.stroke_button_line3::before {
	@include stroke_btn-icon;
	background-image: url('../../../img/dottedLineIcon.svg');
}


.stroke_button_line4::before {
	@include stroke_btn-icon;
	background-image: url('../../../img/dotDashLineIcon.svg');
}

.stroke-block_wrapper {
	margin-bottom: 20px;
}

.stroke-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	color: #fff;
	min-width: 16px;
	height: 24px;
	font-size: 16px;
	line-height: 24px;
	padding: 0;
}

.stroke-section {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.stroke-section_title-block {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.strokeWidth_select {
	background-color: transparent;
	border: none;
	color: #fff;
}

.strokeWidth_option {
	color: #000;
	text-align: center;
}

.stroke {
	position: relative;
}

.stroke-input {
	width: 54px;
	height: 24px;
	padding: 0;
	background-color: #292a32;
	border: 1px solid #383844;
	border-radius: 2px;
	color: #fff;
	text-align: center;
	margin-right: 8px;
}
