#root {
    width: 100%;
    height: 100%;
}

.main-article {
    min-height: 100%;

    @media (max-width: 1019px) {
        height: 100%;
    }
}
