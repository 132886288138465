@mixin clear-icon {
    content: '';
    display: block;
    width: 16px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 13px;
    left: 50%;
    transform: translateX(-50%);
}

.delete-btn {
    position: relative;
    background-color: transparent;
    border: 2px solid transparent;
    width: 60px;
    height: 100%;
    color: #fff;
    cursor: pointer;
    padding: 0;

    &:hover {
        background-color: #131318;
        border: 2px solid #337AA6;
    }

    &::before {
        @include clear-icon;
        background-image: url('../../../img/clear-btn-ico.svg');
    }
}

.clearAll-btn {
    position: relative;
    background-color: transparent;
    border: 2px solid transparent;
    width: 60px;
    height: 100%;
    color: #fff;
    cursor: pointer;
    padding: 0;

    &:hover {
        background-color: #131318;
        border: 2px solid #337AA6;
    }

    &::before {
        @include clear-icon;
        background-image: url('../../../img/noun-clear-487123.svg');
        width: 24px;
        height: 24px;
    }
}

.clear_text {
    display: inline-block;
    padding-top: 28px;
}
