.question-block_wrapper {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-bottom: 5px;
}

.question-block {
	width: 36px;
	height: 36px;
	border-radius: 50%;
	background-color: #080808;
	// position: absolute;
	// bottom: 24px;
	// right: 24px;
}

.question-link {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	text-decoration: none;
	color: #fff;
}
