.sort__block {
    display: flex;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
    border-bottom: 1px solid #383844;
    padding-bottom: 10px;

    @media (max-width: 1019px) {
        display: none;
    }
}

.sort__content {
    display: flex;
    flex-direction: column;
}

.sort__title {
    color: #E5E5E5;
    border-bottom: 1px solid #383844;
    padding-bottom: 10px;
}

.sort__button {
    border: none;
    background-color: inherit;
    color: #AAAAAA;
    padding: 0;
    text-align: start;
    margin-bottom: 10px;

}

.sort__button-active {
    color: #E5E5E5;
}
