@mixin undo-icons {
    content: '';
    display: block;
    width: 16px;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 14px;
    left: 50%;
    transform: translateX(-50%);
}

.undo-section {
    display: flex;
    flex-wrap: nowrap;
    // margin-right: 30px;
}

.last-action_btn {
    position: relative;
    background-color: transparent;
    border: 2px solid transparent;
    width: 60px;
    height: 100%;
    color: #fff;
    cursor: pointer;

    &:hover {
        background-color: #131318;
        border: 2px solid #337AA6;
    }
}

.undo-btn::before {
    @include undo-icons;
    background-image: url('../../../img/undo-icon.svg');
}

.redo-btn::before {
    @include undo-icons;
    background-image: url('../../../img/redo-icon.svg');
}
