@mixin zoom-btn {
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	width: 24px;
	height: 24px;
	padding: 0;
}

// #preview_canvas {
	// width: 1280px;
	// height: 960px;
	// background-color: #ccc;
	// position: relative;
	// margin-left: auto;
	// margin-right: auto;
	// margin-top: 50px;
	// margin-bottom: 40px;
	// display: flex;
	// justify-content: center;
	// align-items: center;
	// background-repeat: no-repeat;
	// background-position: center;
	// background-size: contain;
	// background-image: url('../../../../img/device-ui.svg');
	// border-radius: 300px;
// }

// .preview_canvas-block {
// 	width: 1280px;
// 	height: 960px;
// }

.preview_section {
	display: flex;
	flex-direction: column;
	// justify-content: center;
	flex-grow: 1;
	// max-height: 100%;
	// padding: 3%;
	background-color: #171719;
	// position: relative;
	overflow-y: auto;
	overflow-x: auto;
}

.preview_section-canvas {
	width: 1280px;
	height: 960px;
	// background-color: #fff;
	margin: 0 auto;
}

.preview_section-zoom {
	display: flex;
	// margin: 0 auto;
	// position: absolute;
	// bottom: 3%;
	// left: 50%;
	// transform: translateX(-50%);
	margin-left: auto;
	margin-right: auto;
}

.preview_section-zoom-btn {
	cursor: pointer;
	width: 24px;
	height: 24px;
	background-color: transparent;
	border: none;
}

.preview_zoom-input {
	width: 174px;
	margin: 0 8px;
	position: relative;
}

.zoom-in_btn {
	@include zoom-btn;

	background-image: url('../../../../img/publish/zoom_in.svg');
}

.zoom-out_btn {
	@include zoom-btn;

	background-image: url('../../../../img/publish/zoom_out.svg');
}
