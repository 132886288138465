.targets_section {
	display: flex;
}

.targets_user-section {
	width: 335px;
	box-sizing: border-box;
	background-color: #131318;
	position: relative;
}
