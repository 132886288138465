.lib-section {
    // width: 100%;
    background-color: #1f1f26;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    // width: 100%;
    // justify-content: space-beetwen;
}

.lib-edit-section-block {
    position: relative;
}

.libraries-block {
    width: 100%;
    background-color: #171719;
    box-sizing: border-box;
}
