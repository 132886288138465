.scene_button-block {
	display: flex;
	flex-direction: column;
}

.scene-block {
	overflow-y: auto;
	max-height: 440px
}

.scene_button {
	position: relative;
	background-color: #222329;
	color: white;
	height: 32px;
	text-align: start;
	padding: 0 24px 0 24px;
	border: none;
	cursor: pointer;
}

.buttonTop {
	&::after {
		content: "";
		display: block;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		position: absolute;
		bottom: 30%;
		right: 16px;
		width: 11px;
		height: 10px;
		background-image: url('../../../../img/vectorTop.svg');
	}
}

.buttonBottom {
	&::after {
		content: "";
		display: block;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		position: absolute;
		bottom: 30%;
		right: 16px;
		width: 11px;
		height: 10px;
		background-image: url('../../../../img/vectorBottom.svg');
	}
}

.scene_button:not(:last-child) {
	margin-bottom: 13px;
}

.scene_list {
	width: 335px;
	box-sizing: border-box;
	background-color: #131318;
}

.scene_list-title-section {
	// padding: 24px 8px 0 8px;
	margin-bottom: 8px;
}

.scene_list-title {
	padding: 24px 16px 12px 16px;
	margin: 0;
	font-size: 20px;
	line-height: 24px;
	font-weight: 500;
}

.scene_list-title-btn {
	background-color: transparent;
	border: none;
	color: #fff;
	padding-left: 19px;
	position: relative;
	cursor: pointer;
}
