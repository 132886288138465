.reticleCard {
    position: relative;
    list-style: none;
    width: calc(100% - 2 * 12px);
    min-height: 212px;
    cursor: pointer;
    margin: 12px;

    @media (min-width: 768px) {
        width: calc((100% - 4 * 12px)/2);
        margin: 12px;
    }

    @media (min-width: 1360px) {
        width: calc((100% - 6 * 12px)/3);
        margin: 12px;
    }

    @media (min-width: 1723px) {
        width: calc((100% - 8 * 12px)/4);
        margin: 12px;
    }
}

.reticleCard__body::before {
    content: '';

    display: inline-block;
    position: absolute;
    width: 100%;
    height: 184px;
    background-color: #91A1AD;
    opacity: 0;
}

.reticleCard__body:hover::before {
    opacity: 0.5;
    border-radius: 32px;
}

.reticleCard__body-actions {
    position: absolute;
    top: 20px;
    right: 15px;
    background-color: transparent;
    border: none;
    opacity: 0;
    cursor: pointer;
}

.reticleCard__body:hover .reticleCard__body-actions {
    opacity: 1;
}

.reticleCard__body-actionsTooltip {
    position: absolute;
    top: 0;
    right: 15px;
    background-color: #080808;
    width: 100px;
    text-align: center;
}

.reticleCard__body-checkbox {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 15px;
    right: 25px;
    accent-color: #337aa6;
}

.reticleCard__body-actionsTooltipButton {
    margin: 0;
    padding: 5px;
    border: 2px solid transparent;
    box-sizing: border-box;

    &:hover {
        border: 2px solid #225A7C;
    }

    &:active {
        background-color: #16405A;
        border: 2px solid #16405A;
    }
}

.reticleCard__title {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 16px;
    color: #E5E5E5;
}

.reticleCard__description {
    margin: 0;
    font-size: 14px;
    color: #AAAAAA;
}

.reticleCard__container {
    display: flex;
    padding: 8px 5px;
}

.reticleCard__body-vsg {
    height: 184px;
    border-radius: 32px;
}

.reticleCard__body-vsg>svg {
    width: 100%;
    height: 100%;
    background-color: #e5e5e5;
    border-radius: 32px;
}

.reticleCard__body-vsg>svg#svgCanvas {
    width: 100%;
    height: 100%;
    background-color: #e5e5e5;
    border-radius: 32px;
}

.reticleCard__user-photo {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 16px;
}
