@mixin tab-btn_img {
    content: '';
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: transparent;
    width: 10px;
    height: 10px;
    cursor: pointer;
    border: none;
    margin-left: 10px;
}

// .tabs_section {
// 	display: flex;
// 	align-items: center;
// 	position: relative;
// }

.new-doc_btn {
	@include tab-btn_img;
	background-image: url('../../img/new-tab.svg');
}

.tabs__item {
    list-style: none;
}

.new-doc_btn-invisible {
    display: none;
}