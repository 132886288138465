.fill-block_wrapper {
	margin-bottom: 20px;
}

.color-input {
	max-width: 16px;
	height: 16px;
	border: none;
	padding: 0;
	margin-right: 8px;
}

.color-hex-input {
	max-width: 60px;
}

.fill-input {
	width: 54px;
    height: 24px;
	padding: 0;
	background-color: #292a32;
	border: 1px solid #383844;
	border-radius: 2px;
	color: #fff;
	text-align: center;
	margin-right: 8px;
}

.fill-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	color: #fff;
	min-width: 16px;
	height: 24px;
	font-size: 16px;
	line-height: 24px;
	padding: 0;
}

.edit-section-block {
	border-bottom: 0.5px solid #e6e6e6;	
}

.edit-section_title-block {
	display: flex;
	justify-content: space-between;
	align-items: center;
}


