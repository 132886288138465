.library-block {
    // position: relative;
    overflow-y: auto;
    max-height: calc(100vh - 270px);
}

.logIn_btn {
    width: 139px;
    height: 40px;
    background-color: #337AA6;
    border: 1px solid #337AA6;
    box-sizing: border-box;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;

    &:active {
        background-color: #337AA6;
    }
}

.library__name {
    margin-top: 24px;
    margin-left: 13px;
}

// .search__input {
//     box-sizing: border-box;
//     width: 311px;
//     margin-left: 13px;
//     margin-bottom: 20px;
//     padding-left: 26px;
//     background-color: transparent;
//     border: 1px solid #3F414B;
//     color: #fff;
//     height: 24px;
//     position: relative;
//     background: url('../../../img/search-icon.svg') no-repeat 8px center;
// }

.sortValue__block {
    display: flex;
    margin-left: 13px;
    margin-bottom: 17px;
}

.sortValue__select {
    background-color: transparent;
    border: none;
    color: #fff;
}

// .dropdown {
//     z-index: 10000;
//     width: 140px;
//     position: relative;
//     margin-left: 10px;
// }

// .dropdown,
// .dropdown * {
//     z-index: 10;
// }

// .dropdown .dropdown-btn {
//     cursor: pointer;
//     background: white;
//     align-items: center;
//     justify-content: space-between;
//     background: transparent;
//     color: white;
//     height: 20px;
// }

// .dropdown-content {
//     position: absolute;
//     background-color: #454751;
//     /* top: 110%; */
//     left: 0;
//     width: 100%;
//     box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
// }

// .dropdown-content .item {
//     padding: 10px;
//     cursor: pointer;
//     border: 2px solid transparent;

//     &:hover {
//         border-color: #337aa6;
//     }
// }

// .vectorTop {
//     &::after {
//         content: "";
//         display: block;
//         background-repeat: no-repeat;
//         background-position: center;
//         background-size: contain;
//         position: absolute;
//         bottom: 20%;
//         right: 16px;
//         width: 11px;
//         height: 10px;
//         background-image: url('../../../img/vectorTop.svg');
//     }
// }

// .vectorBottom {
//     &::after {
//         content: "";
//         display: block;
//         background-repeat: no-repeat;
//         background-position: center;
//         background-size: contain;
//         position: absolute;
//         bottom: 20%;
//         right: 16px;
//         width: 11px;
//         height: 10px;
//         background-image: url('../../../img/vectorBottom.svg');
//     }
// }
